export default {

	// api请求地址
	baseUrl: url(),

	// 图片域名
	imgDomain: url(),

	// 腾讯地图key
	mpKey: '',

	// 客服
	webSocket: '',

	// api安全
	apiSecurity: "",

	//本地端主动给服务器ping的时间, 0 则	不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}


function url() {
	// let arg = ['shop.iebobo.com','alindabag.com', 'lindabag.cc', 'wontonsway.com', 'smartbuy.lol'];
	// for (let i = 0; i < arg.length; i++) {
	// 	if (window.location.hostname.indexOf(arg[i]) != -1) {
	// 		if (arg[i] == 'wontonsway.com') return 'https://shop.' + arg[i];
	// 		if (arg[i] == 'shop.iebobo.com') return 'https://shopad.iebobo.com';
	// 		return 'https://api.' + arg[i];
	// 	}
	// }
	// return 'http://43.153.118.97:90';
	return 'https://shopad.orientbuyer.com';
	// return 'https://shopad.iebobo.com';
	// return 'https://api.smartbuy.lol';
	// return 'https://ad.afribaba.business'
	// return 'https://api.llshop.cc';
	// return 'https://manad.llshop.cc';
	// return 'https://aplusad.llshop.cc';
}